<template>
    <div>
        <Header index="0"/>
        <div class="container">
            <!-- 左侧内容显示 -->
            <div class="container-left">
                <el-row>
                    <el-col :span="12" v-for="(item, index) of list" :key="index">
                        <el-card style="width: 95%">
                            <div class="left-content">
                                <p style="font-size: 60px" class="iconfont icon-yonghu"></p>
                                <div class="left-title">{{ item.title }}</div>
                                <div>
                                    <el-tag>游戏代购</el-tag>
                                    <el-tag>游戏问题</el-tag>
                                    <el-tag>退款指导</el-tag>
                                </div>
                                <div>
                                    <p>
                                    </p>
                                </div>
                                <div>
                                    <el-button type="primary" @click="dialogFormVisible = true">聊天</el-button>
                                    <el-button type="success" @click="fufei(index)">付费</el-button>
                                    <el-button type="primary" @click="ConsultantDetail">详情</el-button>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>

            <!-- 右侧内容显示 -->
            <Swiperlist/>

            <!-- 弹窗 -->
            <el-dialog fullscreen :title="title" :visible.sync="dialogFormVisible">
                <div class="dialog-content">
                    <!-- 聊天对话框 -->
                    <div
                            class="dialog-content-left"
                            v-for="(item, index) of contentList"
                            :key="index"
                    >
                        {{ item.value }}
                    </div>
                </div>
                <!-- 对话框按钮 -->
                <div slot="footer" class="dialog-footer">
                    <el-input type="text" v-model="userInput"/>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="changeOK(userInput)">发 送</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header/index";
import Swiperlist from "@/components/Swiperlist/index";

export default {
    mounted() {
        this.index = this.$route.query.index;
    },
    data() {
        return {
            list: [
                {title: "一号客服"},
                {title: "二号客服"},
                {title: "三号客服"},
                {title: "四号客服"},
                {title: "五号客服"},
            ],
            kefuhuiying: "系统维护,请转到Steam详情页",
            index: "",
            userInput: "", //弹窗输入内容
            title: "客服为你服务", //弹窗标题
            contentList: [
                {
                    value: "您好，欢迎使用聊天功能",
                },
            ],
            dialogFormVisible: false,
            activeName: "first",
        };
    },
    methods: {
        ConsultantDetail() {
            this.$router.push({
                name: "ConsultantDetail",
            });
        },
        fufei(index) {
            this.$router.push({
                name: "fufei",
                query: {
                    index,
                },
            });
        },

        //   发送内容
        changeOK(userInput) {
            this.contentList.push({
                value: userInput,
            });
            setTimeout(() => {
                this.contentList.push({
                    value: this.kefuhuiying,
                });
            }, 1000);
            //   input输入框刷新
            this.userInput = "";
        },

        changeNewPage(index) {
            this.$router.push({
                name: "informations",
                query: {
                    ziXunList: this.ziXunList[index],
                },
            });
        },

        handleClick(tab, event) {
          console.log(tab,event)
        },
    },
    components: {
        Header,
        Swiperlist,
    },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_2824970_ph5iej5lot.css");

.container {
    width: 8.163265rem /* 1200/147 */;
    margin: 0 auto;
    margin-top: 0.47619rem /* 70/147 */;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-left {
    width: 900px;
    background-color: white;
    margin-bottom: 20px;
    font-size: 14px;
}

.container-right > img {
    margin: 5px 0;
}

.left-content {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
}


.dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dialog-content {
    width: 100%;
    height: 500px;
    background-color: rgba(255, 255, 0, 0.1);
    overflow-y: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
}


.dialog-content-left {
    color: black;
    background-color: rgb(240, 240, 240);
    text-align: center;
    max-width: 200px;
    margin: 10px 0;
    padding: 10px 10px;
    border-radius: 5px;
}
</style>
