// const allurl = "http://localhost:9090/"
const allurl = "https://api.psnsgame.com/"
// const allurl = "http://43.143.148.231:9090/"
const pyurl = "http://43.143.148.231:5000/"
const steam = "https://api.steampowered.com/"
const psn = "https://psn.flipscreen.games/"

export default {
    allurl,
    psn,
    pyurl,
    steam
}