<template>
  <div>
    <Header index="2"/>
    <el-row :gutter="10" type="flex">
      <el-col class="data">

        <div class="container">
          <div class="container-left">
            <div class="schools">
              <!--        主体部分-->
              <el-main class="main-f">
                <!--            搜索栏-->
                <div>

                  <el-tabs type="border-card">
                    <el-tab-pane label="pc游戏" v-loading="loading" element-loading-text="如果长时间未加载请刷新页面">
                      <div style="margin: 10px 0;float: left">
                        <el-input
                            v-model="input"
                            placeholder="游戏名称"
                            style="width: 400px"></el-input>
                        <el-select v-model="input1" clearable filterable placeholder="请选择类型">
                          <el-option
                              v-for="item in gametype"
                              :key="item"
                              :label="item"
                              :value="item">
                          </el-option>
                        </el-select>
                        <el-input
                            v-model="input2"
                            placeholder="游戏评价"
                            style="width: 200px"></el-input>
                        <el-button type="success" @click="pcsearch">查询</el-button>
                        <el-button type="primary" @click="dialogFormVisible = true">获取游戏</el-button>
                      </div>
                      <div>
                        <table style="width: 100%; border-collapse: collapse">
                          <tbody>
                          <template v-for="i in gameList" >
                            <tr
                                style="
                                border-bottom: 1px solid #eeeeee;
                                border-top: 1px solid #eeeeee;
                              "
                                :key="i.id"
                            >
                              <td class="school-icon" style="padding: 20px 0">
                                <a @click="changeGogame(i)"
                                   class="link_style"
                                   target="_blank"
                                >
                                  <img class="imgsize" :src='i.imgsrc'/>
                                </a>
                                <el-tag type="danger" style="display: block;text-align: center">价格：{{
                                    i.price
                                  }}
                                </el-tag>
                              </td>
                              <td class="name-des">
                                <el-row>
                                  <el-col :span="5">
                                    <div class="grid-content bg-purple">
                                      <div class="top-item">
                                        <a
                                            @click="changeGogame(i)"
                                            class="link_style"
                                            style="width: 72%"
                                            target="_blank"
                                        >
                                          <el-col
                                              :span="24"
                                              class="colorhover"
                                          >{{ i.name }}
                                          </el-col>
                                          <el-col
                                              :span="24"
                                              style="
                                              box-shadow: 0 2px 4px
                                                  rgba(0, 0, 0, 0.12),
                                                0 0 6px rgba(0, 0, 0, 0.04);
                                              font-size: 12px;
                                              padding: 4px;
                                            ">
                                            游戏id：{{ i.gameid }}
                                          </el-col>
                                        </a>
                                      </div>
                                    </div>
                                  </el-col>
                                  <el-col :span="13">
                                    <div class="grid-content bg-purple-light">
                                      <div
                                          class="bottom-item"
                                          style="margin-top: 10px;float: left"
                                      >
                                        <el-tag type="success">好评率：{{
                                            i.goodrating
                                          }}%
                                        </el-tag>
                                        <el-tag>评价：{{
                                            i.recentreviews
                                          }}
                                        </el-tag>
                                        <el-tag>发行日期：{{
                                            i.releasedate
                                          }}
                                        </el-tag>
                                        <el-tag>开发商：{{
                                            i.developers
                                          }}
                                        </el-tag>
                                      </div>
                                    </div>
                                  </el-col>
                                  <el-col :span="6">
                                    <div class="grid-content bg-purple">
                                      <div style="float: right;width: 200px">
                                        <div
                                            class="
                                            el-divider el-divider--vertical
                                          "
                                            style="height: 3em"
                                        ></div>
                                        <!--                                                    两个按钮，功能未定，无用可以删掉-->
                                        <el-button type="primary" size="small"
                                                   @click="changeGogame(i)">游戏详情
                                        </el-button>
                                      </div>
                                    </div>
                                  </el-col>
                                </el-row>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>

                        <!--                        分页-->
                        <div>
                          <el-pagination
                              @size-change="handleSizeChange"
                              @current-change="handleCurrentChange"
                              :current-page="currentPage"
                              :page-sizes="[5, 10, 20]"
                              :page-size="pageSize"
                              layout="total, sizes, prev, pager, next, jumper"
                              :total="total"
                          >
                          </el-pagination>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="ps游戏">
                      <div style="margin: 10px 0;float: left">
                        <el-input
                            v-model="psinput"
                            placeholder="游戏名称"
                            style="width: 400px"
                        ></el-input>
                        <el-select v-model="psinput1" clearable placeholder="请选择区服">
                          <el-option
                              v-for="item in region"
                              :key="item.id"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                        <el-select v-model="psinput2" clearable filterable placeholder="请选择语言">
                          <el-option
                              v-for="item in pslanguega"
                              :key="item"
                              :label="item"
                              :value="item">
                          </el-option>
                        </el-select>
                        <el-button type="success" @click="pssearch">查询</el-button>
                        <el-button type="primary" @click="psdialogFormVisible = true">获取账户id</el-button>
                      </div>
                      <div>
                        <table style="width: 100%; border-collapse: collapse">
                          <tbody>
                          <template v-for="i in psgameList">
                            <tr
                                style="
                                border-bottom: 1px solid #eeeeee;
                                border-top: 1px solid #eeeeee;
                              "
                                :key="i.id"
                            >
                              <td class="school-icon" style="padding: 20px 0">
                                <a @click="changeGopsgame(i)"
                                   class="link_style"
                                   target="_blank"
                                >
                                  <img style="width: 128px;height: 128px" :src='i.picmulu'/>
                                </a>
                                <el-tag type="danger" style="display: block;text-align: center">价格：{{
                                    i.basePrice
                                  }}
                                </el-tag>
                              </td>
                              <td class="name-des">
                                <el-row>
                                  <el-col :span="5">
                                    <div class="grid-content bg-purple">
                                      <div class="top-item">
                                        <a
                                            @click="changeGopsgame(i)"
                                            class="link_style"
                                            style="width: 72%"
                                            target="_blank"
                                        >
                                          <el-col
                                              :span="24"
                                              class="colorhover"
                                          >{{ i.name }}
                                          </el-col>
                                          <el-col
                                              :span="24"
                                              style="
                                              box-shadow: 0 2px 4px
                                                  rgba(0, 0, 0, 0.12),
                                                0 0 6px rgba(0, 0, 0, 0.04);
                                              font-size: 12px;
                                              padding: 4px;
                                            "
                                          >
                                            游戏id：{{ i.gameid }}
                                          </el-col>
                                        </a>
                                      </div>
                                    </div>
                                  </el-col>
                                  <el-col :span="13">
                                    <div class="grid-content bg-purple-light">
                                      <div
                                          class="bottom-item"
                                          style="margin-top: 10px;float: left"
                                      >
                                        <el-tag>类型：{{
                                            i.type
                                          }}
                                        </el-tag>
                                        <el-tag>发行日期：{{
                                            i.releaseDate
                                          }}
                                        </el-tag>
                                        <el-tag>发行商：{{
                                            i.publisherName
                                          }}
                                        </el-tag>
                                        <el-tag type="success" effect="light" v-if="i.containchina">支持中文
                                        </el-tag>
                                      </div>
                                    </div>
                                  </el-col>
                                  <el-col :span="6">
                                    <div class="grid-content bg-purple">
                                      <div style="float: right;width: 200px">
                                        <div
                                            class="
                                            el-divider el-divider--vertical
                                          "
                                            style="height: 3em"
                                        ></div>
                                        <!--                                                    两个按钮，功能未定，无用可以删掉-->
                                        <el-button type="primary" size="small"
                                                   @click="changeGopsgame(i)">游戏详情
                                        </el-button>
                                      </div>
                                    </div>
                                  </el-col>
                                </el-row>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                        <!--                        分页-->
                        <div>
                          <el-pagination
                              @size-change="pshandleSizeChange"
                              @current-change="pshandleCurrentChange"
                              :current-page="pscurrentPage"
                              :page-sizes="[5, 10, 20]"
                              :page-size="pspageSize"
                              layout="total, sizes, prev, pager, next, jumper"
                              :total="pstotal"
                          >
                          </el-pagination>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>

                  <el-dialog title="实时获取游戏" :visible.sync="dialogFormVisible" v-loading="loading"
                             element-loading-text="获取中 预计10s-15s 请稍等">
                    <el-form>
                      <el-form-item label="游戏id">
                        <el-input v-model="newgameid" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="dialogFormVisible = false">取 消</el-button>
                      <el-button type="primary" @click="newgameget">确 定</el-button>
                    </div>
                  </el-dialog>

                  <el-dialog title="ps用户信息" :visible.sync="psdialogFormVisible" v-loading="loading"
                             element-loading-text="获取中 预计10s-15s 请稍等">
                    <el-form>
                      <el-form-item label="psn用户名">
                        <el-input v-model="psnname" autocomplete="off"></el-input>
                      </el-form-item>
                    </el-form>
                    <div>Account ID (PSPlay)：{{ psnnameres.encoded_id }}</div>
                    <div>Encoded ID (Chiaki)：{{ psnnameres.user_id }}</div>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="psdialogFormVisible = false">取 消</el-button>
                      <el-button type="primary" @click="psgetuser">确 定</el-button>
                    </div>
                  </el-dialog>

                </div>
              </el-main>
            </div>
          </div>
          <!-- 右侧内容显示 -->
          <!--          <swiperlist/>-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/Header/index";
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";


export default {

  data() {
    return {
      //以下为ps游戏参数
      psgameList: [],
      pscurrentPage: 2,
      pspageSize: 20,
      pstotal: 0,
      psinput: '',
      psinput1: '',
      psinput2: '',
      //以下为pc游戏参数
      gameList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      input: '',
      input1: '',
      input2: '',
      loading: true,
      newgameid: 0,
      gametype: [],


      psnname: "",
      psdialogFormVisible: false,
      dialogFormVisible: false,
      psnnameres: "",
      region: [{
        value: 'zh-hans-hk',
        label: '港区'
      }, {
        value: 'ja-jp',
        label: '日区'
      }, {
        value: 'en-us',
        label: '美区'
      }, {
        value: 'en-gb',
        label: '英区'
      }],
      pslanguega: []
    };
  },
  template: ``,
  computed: {},
  methods: {
//pc游戏方法
    pcsearch() {
      this.currentPage = 1
      this.gameget()
    },
    // async gameget() {
    //   this.loading = true;
    //   this.gameList = null;
    //   console.log("获取游戏");
    //
    //   try {
    //     const res = await axios.get(this.$globle.allurl + "getpagegame", {
    //       params: {
    //         pagenum: this.currentPage,
    //         pagesize: this.pageSize,
    //         name: this.input,
    //         tab: this.input1,
    //         Recentreviews: this.input2,
    //       }
    //     });
    //
    //     this.loading = false;
    //     console.log(res);
    //
    //     this.gameList = res.data.pagegame;
    //     this.total = res.data.countgame;
    //
    //     for (const game of this.gameList) {
    //       const playerCountRes = await axios.get(this.steam + "/ISteamUserStats/GetNumberOfCurrentPlayers/v1?appid=" + game.gameid);
    //       game.player_count = playerCountRes.data.response.player_count;
    //
    //       game.imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + game.gameid + "/header.jpg";
    //
    //       if (game.price === 0) {
    //         game.price = "免费开玩";
    //       }
    //
    //       console.log(game);
    //     }
    //   } catch (error) {
    //     console.error("获取游戏数据时出错：", error);
    //   }
    // },


    gameget() {
      this.loading = true
      this.gameList = null

      axios.get(this.$globle.allurl + "getpagegame", {
        params: {
          pagenum: this.currentPage,
          pagesize: this.pageSize,
          name: this.input,
          tab: this.input1,
          Recentreviews: this.input2,
        }
      }).then(async res => {
        this.loading = false
        this.gameList = res.data.pagegame
        this.total = res.data.countgame
        for (let i = 0; i < this.gameList.length; i++) {
          this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg"
          if (this.gameList[i].price === 0) {
            this.gameList[i].price = "免费开玩"
          }
        }
      })
    },

    getgametype() {
      axios.get(this.$globle.allurl + "typecount").then(res => {
        this.gametype = res.data
      })
    },

    changeGogame(res) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: res.id,
          gameid: res.gameid
        },
      });
    },

    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.gameget()
    },

    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.gameget()
    },

    newgameget() {
      this.loading = true
      axios.get(this.$globle.pyurl + "predict/" + this.newgameid).then(res => {
        if (res.data.status === "success") {
          this.$router.push({
            name: "gameDetail",
            query: {
              id: res.data.data,
            },
          });
          this.dialogFormVisible = false
        } else if (res.data.status === "failed") {
          this.dialogFormVisible = false
          this.$message({
            type:"success",
            message:"获取失败"
          });
        }

        this.loading = false
      })
    },
    //pc游戏方法结束

    //ps游戏方法
    pssearch() {
      this.pscurrentPage = 1
      this.psgameget()
    },
    psgameget() {
      axios.get(this.$globle.allurl + "ps/getpspagegame", {
        params: {
          pagenum: this.pscurrentPage,
          pagesize: this.pspageSize,
          name: this.psinput,
          region: this.psinput1,
          screenLanguages: this.psinput2,
        }
      }).then(res => {
        this.psgameList = res.data.records
        for (let i = 0; i < this.psgameList.length; i++) {
          this.psgameList[i].containchina = this.psgameList[i].screenLanguages.includes("中文")
        }
        this.pstotal = res.data.total
      })
    },

    changeGopsgame(res) {
      this.$router.push({
        name: "psgameDetail",
        query: {
          id: res.id,
        },
      });
    },

    pshandleSizeChange(pageSize) {
      this.pspageSize = pageSize;
      this.psgameget()
    },

    pshandleCurrentChange(currentPage) {
      this.pscurrentPage = currentPage;
      this.psgameget()
    },
    getpslanguage() {
      axios.get(this.$globle.allurl + "ps/psLanguage").then(res => {
        this.pslanguega = res.data
      })
    },
    psgetuser() {
      this.loading = true
      axios.get(this.$globle.allurl + "ps/getuserid/" + this.psnname).then(res => {
        this.psnnameres = res.data
        this.loading = false
      })
    },
  },
  mounted() {
    this.getpslanguage()
    this.gameget()
    this.psgameget()
    this.getgametype()
  },
  components: {
    Header,
    // Swiperlist,
  },
};
</script>

<style scoped>
@import url("../../css/index.css");
@import url("../../css/mycss.css");

.container {
  margin: 0 auto;
  /*margin-top: 0.1rem !* 70/147 *!;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-left {
  /*width: 960px;*/
  background-color: white;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 14px;
}

.container-right > img {
  margin: 5px 0;
}

.data {
  text-align: center;
  margin: 0 auto;
}
</style>
