<template>
  <el-row :gutter="10">
    <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
    >
      <div class="container-right">
        <div class="tuijian">
          <i></i><span style="font-size: 20px">推荐游戏</span>
        </div>
        <div class="swiperList">
          <div class="rangking">
            <div
                class="rangkingitem"
                @mouseover="mouseover(index)"
                @mouseout="mouseout(index)"
                v-for="(item, index) of gameList"
                :key="index"
                v-show="item.imgsrc"
                @click="changelink(item.link)"
            >
              <span class="item_number">{{ item.id+1 }}</span>
              <img :class="item.srcstyle" :src="item.imgsrc" />
              <!--      <p>{{item.src}}</p>-->
              <div class="item_right">
                <span :class="item.rihtTitle">{{ item.name }}</span>
                <span v-show="item.ifhiddentext" class="rihtText">{{
                  item.recentreviews
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";

export default {
  name: "ranking",
  data() {
    return {
      index: "0",
      gameList:[],
    };
  },
  methods: {
    changeLeftItem(index) {
      sessionStorage.setItem("passage",index)

      this.$router.push({
        name: "information"
      });
    },

    gameget(){
      axios.get(this.$globle.allurl+"getHotgame").then(res=>{
        this.gameList=res.data
        for (let i = 0; i < this.gameList.length; i++) {
          this.gameList[i].imgsrc="https://media.st.dl.eccdnx.com/steam/apps/"+this.gameList[i].gameid+"/header.jpg"
          this.gameList[i].srcstyle="crcnoselected"
          this.gameList[i].rihtTitle="titlenoselected"
          this.gameList[i].ifhiddentext=false
        }
      })
    },
    changelink(event) {
      // window.location.href = event;
      // 新窗口打开
      window.open(event);
    },

    mouseout(index) {
      this.gameList[index].ifhiddentext = false;
      this.gameList[index].srcstyle = "crcnoselected";
      this.gameList[index].rihtTitle = "titlenoselected";
    },
    mouseover(index) {
      this.index = index;
      this.gameList[index].ifhiddentext = true;
      this.gameList[index].srcstyle = "crcselected";
      this.gameList[index].rihtTitle = "titleselected";
      for (let i in this.gameList) {
        this.gameList[i].ifhiddentext = false;
        this.gameList[i].srcstyle = "crcnoselected";
        this.gameList[i].rihtTitle = "titlenoselected";
        this.gameList[index].ifhiddentext = true;
        this.gameList[index].srcstyle = "crcselected";
        this.gameList[index].rihtTitle = "titleselected";
      }
    },
  },
  mounted() {
    this.gameget()
  },
};
</script>

<style scoped>
.rangking {
  display: flex;
  flex-direction: column;
}

.rangkingitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.rangkingitem > img {
  border-radius: 50%;
}


.item_number {
  background-color: rgb(192, 192, 192);
  margin: 0 10px 0 20px;
  padding: 2px;
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
}

.rangkingitem:nth-child(2) > .item_number {
  background-color: red;
}

.rangkingitem:nth-child(3) > .item_number {
  background-color: rgb(255, 107, 107);
}

.rangkingitem:nth-child(4) > .item_number {
  background-color: rgb(255, 193, 193);
}

.item_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  text-align: left;
  cursor: pointer;
}

.rihtText {
  color: #999;
  font-size: 12px;
}

.item_right > span:hover {
  transition: 1s;
  color: tomato;
}
.container-right {
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: center;
}

.container-right > img {
  margin: 5px 0;
}

.swiperList {
  width: 100%;
  border: 1px solid rgb(240, 240, 240);
}

.tuijian {
  margin: 10px 0 15px;
  text-align: left;
}


.content-value {
  /* 超出文本省略 */
  text-overflow: ellipsis;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.content-value:hover {
  color: tomato;
  transition-duration: 0.5s;
}
.crcnoselected {
  width: 30px;
  height: 30px;
}

.crcselected {
  width: 70px;
  height: 70px;
}
</style>
