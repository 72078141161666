<template>
    <div>
        <Header index="1"/>
        <div class="content">
            <el-row :gutter="10" type="flex">
                <el-col class="data" :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
                    <div class="grid-content bg-purple">
                        <section>
                            <el-row>
                                <!--考生信息表单-->
                                <el-col :span="8" :offset="4">

                                    <el-form>
                                        <el-form-item label="最早发行日期">
                                            <div class="block">
                                                <el-date-picker
                                                    v-model="recommendGame.releasedate"
                                                    type="date"
                                                    placeholder="选择日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="价格上限">
                                            <el-input v-model="recommendGame.price"></el-input>
                                        </el-form-item>

                                        <el-form-item label="标签">
                                            <el-input v-model="recommendGame.tab"></el-input>
                                        </el-form-item>

                                        <el-form-item label="评价">
                                            <el-select v-model="recommendGame.recentreviews" placeholder="请选择">
                                                <el-option
                                                        v-for="item in options"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label="最高评价数量">
                                            <el-input v-model="recommendGame.numberofreviews"></el-input>
                                        </el-form-item>
                                        <el-form-item label="最低好评率">
                                            <el-input v-model="recommendGame.goodrating"></el-input>
                                        </el-form-item>
                                        <el-form-item label="游戏支持语言">
                                            <el-input v-model="recommendGame.lang"></el-input>
                                        </el-form-item>
                                    </el-form>
                                </el-col>

                                <!--右侧圆形按钮-->
                                <el-col :span="4" :offset="4">
                                    <div>
                                        <el-button
                                                class="move-bottom-large info-button"
                                                type="primary"
                                                @click="getgameInfo" style="width: 200px;
                                                  height: 200px;
                                                  border-radius: 100px;"
                                        >推荐
                                        </el-button
                                        >
                                    </div>
                                </el-col>
                            </el-row>
                        </section>
                    </div>
                    <div>
                        <table style="width: 100%; border-collapse: collapse">
                            <tbody>
                            <template
                                    v-for="i in gameList"
                            >
                                <tr
                                        style="
                                border-bottom: 1px solid #eeeeee;
                                border-top: 1px solid #eeeeee;
                              "
                                        :key="i.id"
                                >
                                    <td class="school-icon" style="padding: 20px 0">
                                        <a @click="changeGogame(i)"
                                           class="link_style"
                                           style="width: 72%"
                                           target="_blank"
                                        >
                                            <img class="imgsize" :src='i.imgsrc'/>
                                        </a>
                                        <el-tag type="danger" style="margin-left: 36px;">价格：{{
                                            i.price
                                            }}
                                        </el-tag>
                                    </td>
                                    <td class="name-des">
                                        <el-row>
                                            <el-col :span="5">
                                                <div class="grid-content bg-purple">
                                                    <div class="top-item">
                                                        <a
                                                                @click="changeGogame(i)"
                                                                class="link_style"
                                                                style="width: 72%"
                                                                target="_blank"
                                                        >
                                                            <el-col
                                                                    :span="24"
                                                                    class="colorhover"
                                                            >{{ i.name }}
                                                            </el-col
                                                            >
                                                            <el-col
                                                                    :span="24"
                                                                    style="
                                              box-shadow: 0 2px 4px
                                                  rgba(0, 0, 0, 0.12),
                                                0 0 6px rgba(0, 0, 0, 0.04);
                                              font-size: 12px;
                                              padding: 4px;
                                            "
                                                            >
                                                                游戏id：{{ i.gameid }}
                                                            </el-col>
                                                        </a>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :span="19">
                                                <div class="grid-content bg-purple-light">
                                                    <div
                                                            class="bottom-item"
                                                            style="margin-top: 10px;float: left"
                                                    >
                                                        <el-tag type="success">好评率：{{
                                                            i.goodrating
                                                            }}%
                                                        </el-tag>
                                                        <el-tag>评价：{{
                                                            i.recentreviews
                                                            }}
                                                        </el-tag>
                                                        <el-tag>发行日期：{{
                                                            i.releasedate
                                                            }}
                                                        </el-tag>
                                                        <el-tag>开发商：{{
                                                            i.developers
                                                            }}
                                                        </el-tag>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <!--                                        <el-col :span="6">-->
                                            <!--                                            <div class="grid-content bg-purple">-->
                                            <!--                                                <div style="float: right;width: 200px">-->
                                            <!--                                                    <div-->
                                            <!--                                                            class="-->
                                            <!--                                            el-divider el-divider&#45;&#45;vertical-->
                                            <!--                                          "-->
                                            <!--                                                            style="height: 3em"-->
                                            <!--                                                    ></div>-->
                                            <!--                                                    &lt;!&ndash;                                                    两个按钮，功能未定，无用可以删掉&ndash;&gt;-->
                                            <!--                                                    <el-button type="primary" size="small" @click="changeGogame(i)">院校详情-->
                                            <!--                                                    </el-button>-->
                                            <!--                                                    <el-button type="primary" size="small" @click="addWishCandidate(i)">-->
                                            <!--                                                        加入候选单-->
                                            <!--                                                    </el-button>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                            <!--                                        </el-col>-->
                                        </el-row>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>

                        <!--                        分页-->
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header/index";
import axios from "axios";
// import add from "../../css/index.css"
export default {
    data() {
        return {
            options: [{
                value: '特别好评',
                label: '特别好评'
            }, {
                value: '好评',
                label: '好评'
            }, {
                value: '多半好评',
                label: '多半好评'
            }, {
                value: '褒贬不一',
                label: '褒贬不一'
            }, {
                value: '好评如潮',
                label: '好评如潮'
            }, {
                value: '多半差评',
                label: '多半差评'
            }, {
                value: '特别差评',
                label: '特别差评'
            }, {
                value: '差评如潮',
                label: '差评如潮'
            }],
            value: '',
            recommendGame: {
                price: null,
                tab: null,
                recentreviews: null,
                numberofreviews: null,
                goodrating: null,
                lang: null,
            },
            value1: '',
            gameList: {},
        };
    },
    components: {
        Header,
    },

    methods: {
        changeGogame(res) {
            this.$router.push({
                name: "gameDetail",
                query: {
                    id: res.id,
                },
            });
        },
        getgameInfo() {
            axios
                .post(this.$globle.allurl + "getrecommendgame", this.recommendGame)
                .then(res => {
                    this.gameList = res.data
                    this.total = res.data.total
                    for (let i = 0; i < this.gameList.length; i++) {
                        this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg"
                        if (this.gameList[i].price === 0) {
                            this.gameList[i].price = "免费开玩"
                        }
                    }
                })
        },

        goToCandidate() {
            this.$router.push("/details/collection");
        },
    },
};
</script>

<style scoped>
/* @import url("../../css/index.css"); */
@import url("../../css/mycss.css");

.data {
    text-align: center;
    margin: 0 auto;
}

.content {
    margin: 0.544218rem /* 80/147 */ auto;
}

</style>
