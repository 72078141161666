<template>
  <div style="width: 100%;height: 100vh;padding: 0.05px">
    <div style="width: 400px;margin: 100px auto;">
      <div style="font-size: 40px;text-align: center;padding-bottom: 20px">注册</div>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input prefix-icon="el-icon-school-solid" v-model="form.username" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input prefix-icon="el-icon-lock" v-model="form.password" show-password placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input prefix-icon="el-icon-lock" v-model="password" show-password placeholder="确认密码"></el-input>
        </el-form-item>
      </el-form>
      <el-button style="width: 100%;background-color: cornflowerblue;color: white;" @click="login">注册</el-button>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "register",
  data() {
    return {
      password: "",
      form: {},
    }
  },
  methods: {
    login() {
      if (this.form.password === this.password) {
        axios.post(this.$globle.allurl + "user/register", this.form).then(res => {
          if (res.data === 1) {
            this.$message({
              type: "success",
              message: "注册成功"
            })
            this.$router.push({name: "login"})
          } else {
            this.$message({
              type: "error",
              message: "重复"
            })
          }
        })
      } else {
        this.$message({
          type: "error",
          message: "密码不一致"
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
