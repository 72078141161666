<template>
  <div>
    <Header index="0"/>
    <div class="container">

      <el-carousel :interval="4000" type="card" height="400px">
        <el-carousel-item v-for="(item, index) of gameList"
                          :key="index">

          <img  :src="item.imgsrc" @click="changeGogame(item)" style="width: 757px;height: 354px"/>
        </el-carousel-item>
      </el-carousel>

      <el-row style="margin: 0 126px;">
        <el-col :span="24">
          <div class="content-right">
            <div
                class="right-item"
                v-for="(item, index) of rightList"
                :key="index"
                @click="changeGoConsultant(index)"
            >
              <div class="right-item-left">
                <p style="font-size: 20px">{{ item.title }}</p>
                <p style="font-size: 14px; color: #999">点击进入 ></p>
                <div>
                  <img class="right-item-img" :src="item.src" alt="" srcset=""/>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row class="school">
        <el-divider content-position="left" style="height: 20px;background-color: orange">热门游戏</el-divider>
        <el-card>

          <el-col class="school-right" :span="8">
            <el-row>
              <el-col :span="11">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[0].imgsrc" @click="changelink(gameList[0].link)">
                  <h3 class="font1" @click="changelink(gameList[0].link)">{{ gameList[0].name }}</h3>
                </el-card>
              </el-col>
              <el-col :span="11" style="float: right;margin-right: 15px;">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[1].imgsrc" @click="changelink(gameList[1].link)">
                  <h3 @click="changelink(gameList[1].link)">{{ gameList[1].name }}</h3>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[2].imgsrc" @click="changelink(gameList[2].link)">
                  <h3 @click="changelink(gameList[2].link)">{{ gameList[2].name }}</h3>
                </el-card>
              </el-col>
              <el-col :span="11" style="float: right;margin-right: 15px;">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[3].imgsrc" @click="changelink(gameList[3].link)">
                  <h3 @click="changelink(gameList[3].link)">{{ gameList[3].name }}</h3>
                </el-card>
              </el-col>
            </el-row>
          </el-col>

          <el-col class="school-center" :span="8" style="color: white">
            <h3 @click="changelink(gameList[4].link)">{{ gameList[4].name }}</h3>

            <img class="imgsize_1" :src="gameList[4].imgsrc" @click="changelink(gameList[4].link)">
          </el-col>

          <el-col class="school-left" :span="8">
            <el-row>
              <el-col :span="11" style="float: left;margin:0 15px;">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[5].imgsrc" @click="changelink(gameList[5].link)">
                  <h3 @click="changelink(gameList[5].link)">{{ gameList[5].name }}</h3>
                </el-card>
              </el-col>
              <el-col :span="11">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[6].imgsrc" @click="changelink(gameList[6].link)">
                  <h3 @click="changelink(gameList[6].link)">{{ gameList[6].name }}</h3>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11" style="float: left;margin:0 15px;">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[8].imgsrc" @click="changelink(gameList[8].link)">
                  <h3 @click="changelink(gameList[8].link)">{{ gameList[8].name }}</h3>
                </el-card>
              </el-col>
              <el-col :span="11">
                <el-card body-style="height:100px">
                  <img style="width: 65px;height: 65px" :src="gameList[9].imgsrc" @click="changelink(gameList[9].link)">
                  <h3 @click="changelink(gameList[9].link)">{{ gameList[9].name }}</h3>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-card>
      </el-row>

      <el-row style="margin: 20px 126px;">
        <el-divider content-position="left" style="height: 20px;background-color: orange">高考资讯</el-divider>
        <el-col class="data" :span="17">
          <div class="content">
            <!-- 左侧部分 -->
            <el-card class="content-left"
                     v-for="(item, index) of title"
                     :key="index"
            >
              <el-row>
                <p class="titlesp" style="cursor:pointer;"
                   @click="changeLeftItem(item.id)">{{item.title}}</p>
              </el-row>
              <el-row>
                <div v-html="item.text" class="content1"></div>
              </el-row>
            </el-card>
          </div>
          <!-- 右侧部分 -->
        </el-col>
        <el-col :span="6" style="float: right">
          <Swiperlist/>
        </el-col>
      </el-row>

      <div class="guanggao" @click="changeGoConsultant1">广告</div>
      <div class="footer">
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/index";
import Footer from "@/components/Footer/index";
import rig1 from "../../images/index/right1.png";
import rig2 from "../../images/index/right2.png";
import rig3 from "../../images/index/right3.png";
import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";

export default {
  data() {
    return {
      // banner图列表
      gameList:[],


      // banner图右边列表
      bannerRight: [

      ],

      title:[],
      // 咨询列表
      leftList: [],
      //咨询师列表
      rightList: [
        {
          title: "游戏客服",
          src: rig2,
        },
        {
          title: "充值付费",
          src: rig3,
        },
        {
          title: "数据图表",
          src: rig1,
        },
        {
          title: "新闻",
          src: rig3,
        },
      ],
    };
  },
  methods: {
    gameget(){
      axios.get(this.$globle.allurl+"getHotgame").then(res=>{
        this.gameList=res.data
        for (let i = 0; i < this.gameList.length; i++) {
          this.gameList[i].imgsrc="https://media.st.dl.eccdnx.com/steam/apps/"+this.gameList[i].gameid+"/header.jpg"
        }
      })
    },
    Hotarticalget(){
      axios.get(this.$globle.allurl+"gethotartical").then(res=>{
        this.title=res.data
      })
    },

    changelink(event) {
      // window.location.href = event;
      // 新窗口打开
      window.open(event);
    },
    changeGogame(res) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: res.id,
        },
      });
    },
    changeGoConsultant1() {
      this.$router.push({
        name: "consultant",
        query: {
          index: "-1",
        },
      });
    },

    changeGoConsultant(index) {

      if (index == 0) {
        this.$router.push({
          name: "consultant",
          query: {
            index: index,
          },
        });
      } else if (index == 2) {
        this.$router.push("/charts");
      } else if (index == 1) {
        this.$router.push("/fufei");
      } else if (index == 3) {
        this.$router.push("/informations");
      }
    },

    // 跳转资讯详情页面
    changeLeftItem(index) {
      this.$router.push({
        name: "information",
        query: {
          id: index,
        },
      });
    },

  },
  components: {
    Header,
    Footer,
    Swiperlist,
  },
  created() {
    this.Hotarticalget()
    this.gameget()
  },
};
</script>

<style scoped>
.imgsize_1 {
  width: 150px;
  height: 150px;
  margin: 10px;
}
h3{
  font-size: 12px;
}
.el-card__body important{
  height: 100px;
}
.school-left {
  height: 300px;
  margin-bottom: 20px;
}

.school-center {
  height: 300px;
  text-align: center;
  cursor: pointer;
  background-color: orange;
  margin-bottom: 20px;
}

.school-center > h3 {
  margin: 20px 0;
  font-size: 35px;
}

.school-right {
  height: 300px;
  margin-bottom: 20px;
}

.school {
  margin: 0 126px;
}

.titlesp {
  border-bottom: 1px solid green;
  margin-bottom: 30px;
}

.content1 {
  font-size: 10px;
  height: 80px; /*定义块元素的宽度*/
  color: #666;
  line-height: 1.6em;
  text-align: left;
}

.guanggao {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 500px;
  right: 0;
}

.right-item {
  cursor: pointer;
  height: 160px;
  margin: 10px 60px;
  border-radius: 15%;
  justify-content: space-around;
  width: 49.4%;
  align-items: center;
  border: 1px solid #999;
}

.content-right::-webkit-scrollbar {
  display: none;
}

.content-left::-webkit-scrollbar {
  display: none;
}

.right-item-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.content-right {
  border-radius: 5%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-top: 20px;
  overscroll-behavior: contain;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: rgb(216, 216, 216) 0 0 10px;
  /*width: 492px;*/
  /*flex-wrap: wrap;*/
}

.right-item-img {
  width: 50px;
  height: 50px;
}


.content-left {
  width: 100%;
}

p:hover {
  transition-duration: 0.3s;
  color: #ff6602;
}

.content {
  width: 100%;
  height: 187px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container {
  width: 100%;
  height: 414px /* 400/147 */;
  /*margin: 75px !* 70/147 *! auto 0;*/
}


.banner-right > img {
  width: 279px /* 270/147 */;
  height: 82.66px /* 80/147 */;
  margin-top: 16px /* 16/147 */;
}




.content-ite > span {
  cursor: pointer;
  margin: 11px /* 10/147 */ 0;
  width: 134px /* 130/147 */;
}

.content-ite > span:hover {
  transition-duration: 0.2s;
  color: #ff6602;
}

.data {
  text-align: center;
  margin: 0 auto;
}

.footer {
  margin: 100px auto;
}
</style>
