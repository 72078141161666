<template>
    <div>
        <Header index="0"/>
        <el-row style="margin: 20px 126px;">
            <el-col class="data" :span="17">
                <div class="content">
                    <!-- 左侧部分 -->
                    <el-descriptions class="margin-top" :column="3" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                用户ID
                            </template>
                            {{ form.id }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                账号
                            </template>
                            {{ form.username }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                邮箱
                            </template>
                            {{ form.email }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                密码
                            </template>
                            {{ form.password }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                昵称
                            </template>
                            {{ form.nickname }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                手机号
                            </template>
                            {{ form.phone }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                注册时间
                            </template>
                            {{ form.create_time }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                账户角色
                            </template>
                            {{ form.role }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                地址
                            </template>
                            {{ form.address }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                账户余额
                            </template>
                            {{ form.balance }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <div class="title_1">我拥有的游戏</div>
                    <div>
                        <table style="width: 100%; border-collapse: collapse">
                            <tbody>
                            <template
                                    v-for="i in gameList"
                            >
                                <tr
                                        style="
                                border-bottom: 1px solid #eeeeee;
                                border-top: 1px solid #eeeeee;
                              "
                                        :key="i.id"
                                >
                                    <td class="school-icon" style="padding: 20px 0">
                                        <a @click="changeGogame(i)"
                                           class="link_style"
                                           style="width: 72%"
                                           target="_blank"
                                        >
                                            <img class="imgsize" :src='i.imgsrc'/>
                                        </a>
                                        <el-tag type="danger" style="margin-left: 36px;">价格：{{
                                            i.price
                                            }}
                                        </el-tag>
                                    </td>
                                    <td class="name-des">
                                        <el-row>
                                            <el-col :span="5">
                                                <div class="grid-content bg-purple">
                                                    <div class="top-item">
                                                        <a
                                                                @click="changeGogame(i)"
                                                                class="link_style"
                                                                style="width: 72%"
                                                                target="_blank"
                                                        >
                                                            <el-col
                                                                    :span="24"
                                                                    class="colorhover"
                                                            >{{ i.name }}
                                                            </el-col
                                                            >
                                                            <el-col
                                                                    :span="24"
                                                                    style="
                                              box-shadow: 0 2px 4px
                                                  rgba(0, 0, 0, 0.12),
                                                0 0 6px rgba(0, 0, 0, 0.04);
                                              font-size: 12px;
                                              padding: 4px;
                                            "
                                                            >
                                                                游戏id：{{ i.gameid }}
                                                            </el-col>
                                                        </a>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :span="19">
                                                <div class="grid-content bg-purple-light">
                                                    <div
                                                            class="bottom-item"
                                                            style="margin-top: 10px;float: left"
                                                    >
                                                        <el-tag type="success">好评率：{{
                                                            i.goodrating
                                                            }}%
                                                        </el-tag>
                                                        <el-tag>评价：{{
                                                            i.recentreviews
                                                            }}
                                                        </el-tag>
                                                        <el-tag>发行日期：{{
                                                            i.releasedate
                                                            }}
                                                        </el-tag>
                                                        <el-tag>开发商：{{
                                                            i.developers
                                                            }}
                                                        </el-tag>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <!--                                        <el-col :span="6">-->
                                            <!--                                            <div class="grid-content bg-purple">-->
                                            <!--                                                <div style="float: right;width: 200px">-->
                                            <!--                                                    <div-->
                                            <!--                                                            class="-->
                                            <!--                                            el-divider el-divider&#45;&#45;vertical-->
                                            <!--                                          "-->
                                            <!--                                                            style="height: 3em"-->
                                            <!--                                                    ></div>-->
                                            <!--                                                    &lt;!&ndash;                                                    两个按钮，功能未定，无用可以删掉&ndash;&gt;-->
                                            <!--                                                    <el-button type="primary" size="small" @click="changeGogame(i)">院校详情-->
                                            <!--                                                    </el-button>-->
                                            <!--                                                    <el-button type="primary" size="small" @click="addWishCandidate(i)">-->
                                            <!--                                                        加入候选单-->
                                            <!--                                                    </el-button>-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                            <!--                                        </el-col>-->
                                        </el-row>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>

                        <!--                        分页-->
                    </div>
                </div>
                <!-- 右侧部分 -->

            </el-col>
            <el-col :span="6" style="float: right">
                <div class="title_1">充值记录</div>
                <div style="height:700px;overflow-y:auto">
                    <el-card v-for="i in userfee"
                             :key="i.rechargerecord_id">
                        <el-descriptions :column="1">
                            <el-descriptions-item label="套餐">{{ i.recharge_id }}</el-descriptions-item>
                            <el-descriptions-item label="订单号">{{ i.order_number }}</el-descriptions-item>
                            <el-descriptions-item label="购买时间">{{ i.entry_time }}</el-descriptions-item>
                        </el-descriptions>

                    </el-card>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

import Header from "@/components/Header";
// import Swiperlist from "@/components/Swiperlist";
import axios from "axios";

export default {
    components: {
        Header,
        // Swiperlist,
    },
    name: "userdetail",
    data() {
        return {
            gameList: {},
            form: {},
            user: {},
            userfee: {}
        }
    },
    created() {
        let user = localStorage.getItem("user") || "{}"
        this.form = JSON.parse(user)
        this.gameget()
        this.getuserfee()
        this.update()
    },
    methods: {
        changeGogame(res) {
            this.$router.push({
                name: "gameDetail",
                query: {
                    id: res.id,
                },
            });
        },

        //分页方法
        gameget() {
            axios.get(this.$globle.allurl + "getusergame", {
                params: {
                    userid: this.form.id,
                }
            }).then(res => {
                this.gameList = res.data
                this.total = res.data.total
                for (let i = 0; i < this.gameList.length; i++) {
                    this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg"
                    if (this.gameList[i].price === 0) {
                        this.gameList[i].price = "免费开玩"
                    }
                }
            })
        },
        getuserfee() {
            axios.get(this.$globle.allurl + "fee/userfeerecord", {
                params: {
                    userid: this.form.id,
                }
            }).then(res => {
                this.userfee = res.data
            })
        },
        update() {
            axios.get(this.$globle.allurl + "user/getoneuser", {
                params: {
                    userid: this.form.id
                }
            }).then(res => {
                this.form = res.data
            })
        }
    }
}
</script>

<style scoped>

</style>
