<template>
    <div>
        <Header/>
        <div id="container">

            <div>
                <section>
                    <h2 class="my-center">用户充值</h2>

                    <br/>
                    <br/>

                    <el-row>
                        <el-table
                                :data="feeItem"
                                style="width: auto">
                            <el-table-column align="center"
                                             label="套餐编号">
                                <template slot-scope="scope">
                                    <div slot="reference" class="name-wrapper">
                                        <el-tag size="medium">{{ scope.row.recharge_id }}</el-tag>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center"
                                             prop="introduction"
                                             label="套餐信息">
                            </el-table-column>
                            <el-table-column align="center"
                                             prop="price"
                                             label="价格">
                            </el-table-column>
                            <el-table-column align="center"
                                             label="操作">
                                <template slot-scope="scope">
                                    <el-button type="success" slot="reference"
                                               @click="buyItem(scope.row.recharge_id)">购买
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                </section>
            </div>
            <Footer/>
        </div>
    </div>

</template>

<script>
import Header from "@/components/Header/index";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            feeItem: [],
            userId: 1
        };
    },
    mounted() {
        this.page();
    },

    methods: {
        page() {
            axios.get(this.$globle.allurl + "fee/allfee").then((res) => {
                this.feeItem = res.data;
            });
        },
//post这里会报错，原因仍未知
        buyItem(rechargeId) {
            this.userId = JSON.parse(localStorage.getItem("user") || "{}").id
            axios.get(this.$globle.allurl+"fee/insertfeerecord",{
                params:{
                    userid:this.userId,
                    rechargeid:rechargeId
                }
            }).then((res) => {
                if(res.data){
                    this.$alert('购买成功', '提示', {
                        confirmButtonText: '确定',
                    });
                }
            })
        }
    },
};
</script>

<style scoped>
</style>
