<template>
  <div>
    <Header index="2"/>
    <div class="title_1">{{ this.form.name }}</div>
    <div>
      <el-carousel :interval="4000" type="card" height="420px">
        <el-carousel-item v-for="item in this.form.detialimg" :key="item">
          <img :src="item" style="width: 757.5px;height: 420px"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-row :gutter="10" type="flex">
      <el-col class="data" :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <div class="container">
          <el-container>
            <el-main class="main-f">
              <el-card shadow="never">
                <el-row>
                  <el-col :span="12">
                    <div class="grid-content bg-purple">
                      <el-row>
                        <el-col :span="12">
                          <div
                              class="grid-content bg-purple"
                              style="font-size: 20px;color: red"
                          >
                            {{ form.name }}
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div
                              class="grid-content bg-purple"
                              style="font-size: 20px"
                          >
                            <el-rate
                                v-model="value5"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template="{value}"
                            >
                            </el-rate>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <h5>
                            {{ form.description }}
                          </h5>
                        </el-col>
                        <el-col :span="24">
                          <div class="grid-content bg-purple-light">
                            <el-tag
                                type="success"
                                style="font-size: 15px"
                            >
                              游戏id：{{ form.gameid }}
                            </el-tag>
                            <el-tag>评价：{{
                                form.recentreviews
                              }}
                            </el-tag>
                            <el-tag>发行日期：{{
                                form.releasedate
                              }}
                            </el-tag>
                            <el-tag>开发商：{{
                                form.developers
                              }}
                            </el-tag>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div
                        class="grid-content bg-purple-light"
                        style="float: right; width: 100%"
                    >
                      <img
                          class="imgsize_1"
                          v-bind:src="form.imgsrc"
                      />
                    </div>
                  </el-col>
                </el-row>
              </el-card>

              <el-carousel indicator-position="outside" :autoplay="false" height="490px" trigger="click">
                <el-carousel-item v-for="item in this.form.video1" :key="item">
                  <video :src=item controls="controls" width="900px" height="490px"></video>
                </el-carousel-item>
              </el-carousel>

              <el-card shadow="never" style="height:500px;overflow-y:auto">
                <div v-html="form.concerning"></div>
              </el-card>

              <el-card shadow="never">
                <div class="title_1">游戏评价</div>
                <div class="container-left">
                  <el-row style="height:500px;overflow-y:auto">
                    <el-col :span="12" v-for="(item, index) of comments" :key="index">
                      <el-card style="width: 95%">
                        <div class="left-content">
                          <p style="font-size: 60px" class="iconfont icon-yonghu"></p>
                          <div style="height:100px;overflow-y:auto">
                            <p style="font-size: 15px">
                              {{ item }}
                            </p>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
                <!--                <el-card shadow="hover" v-for="item in comments" :key=item>-->
                <!--                  {{item}}-->
                <!--                </el-card>-->
              </el-card>
            </el-main>
          </el-container>

        </div>
        <div>
          <el-card body-style="display:grid">
            <span class="text1">{{ form.recentreviews }}</span>
            <h5>{{ form.acclaim }}</h5>
          </el-card>

          <el-card body-style="display:grid">
            <span class="price">¥{{ form.price }}</span>
            <el-button type="primary" @click="changelink(form.link)">前往steam</el-button>
          </el-card>
          <el-card body-style="display:grid">
            <span class="price" style="font-size: 60px">在线人数{{ playercount }}</span>
            <el-button type="primary" @click="getplayer(form.gameid)">获取人数</el-button>
          </el-card>
          <el-card body-style="display:grid">
            <el-button type="primary" @click="shop(form.id)">购买游戏</el-button>
          </el-card>
          <el-card body-style="display:grid">
            <span>{{ form.releasedate }}发行</span>
            <h5>开发商：{{ form.developers }}</h5>
            <h5>发行商：{{ form.publisher }}</h5>
          </el-card>
          <el-card>
            <el-tag v-for="item in tab" :key="item">{{ item }}</el-tag>
          </el-card>
          <el-card>
            <el-tag v-for="item in language" :key="item">{{ item }}</el-tag>
          </el-card>
          <el-card>
            <div v-html="form.configurationRequirements" style="font-size: 12px;"></div>
          </el-card>
          <el-card body-style="display:grid">
            <el-button type="primary" @click="changelink(form.gameurc)">游戏官网</el-button>
          </el-card>
        </div>
        <!--        <swiperlist style="float: right"/>-->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/Header/index";
// import PeopleNumber from "@/pages/Charts/PeopleNumber";
// import HotSpecialty from "@/pages/Charts/HotSpecialty";
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      fit: "",
      formInline: {
        user: "",
        region: "",
      },
      url: "static/img/htt/1.jpeg",
      value5: 4,
      form: {},
      yearMajor: {},
      input: "",
      comments: [],
      tab: [],
      language: [],
      playercount: 0
    };
  },
  methods: {


    changelink(event) {
      // window.location.href = event;
      // 新窗口打开
      window.open(event);
    },

    shop(id) {
      axios.get(this.$globle.allurl + "shopgame", {
        params: {
          userid: JSON.parse(localStorage.getItem("user") || "{}").id,
          gameid: id
        }
      }).then(res => {
        if (res.data == 1) {
          this.$alert('游戏已购买', '购买失败', {
            confirmButtonText: '确定',
          });
        } else if (res.data == 2) {
          this.$alert('账户余额不足', '购买失败', {
            confirmButtonText: '确定',
          });
        } else if (res.data == 3) {
          this.$alert('游戏愉快', '购买成功', {
            confirmButtonText: '确定',
          });
        }
      })
    },

    load1() {
      var param = this.$route.query.id;

      axios
          .get(this.$globle.allurl + "getonegame/" + param)
          .then((response) => {

            this.form = response.data.game;
            this.form.imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.form.gameid + "/header.jpg"
            this.language = this.form.lang.split("\n")
            this.form.detialimg = []
            this.form.video1 = []
            let detialimg = this.form.pic.split("\n")
            let video = this.form.video.split("\n")
            for (let i = 0; i < detialimg.length; i++) {
              this.form.detialimg.push("https://media.st.dl.eccdnx.com/steam/apps/" + this.form.gameid + "/" + detialimg[i])
            }
            for (let i = 0; i < video.length; i++) {
              this.form.video1.push(video[i])
            }
            this.form.videosrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.form.gameid + "/movie480_vp9.webm"
            if (this.form.price === 0) {
              this.form.price = "免费开玩"
            }
            document.title = this.form.name;
            this.comments = this.form.comments.split("\n")
            this.tab = this.form.tab.split("\n")
            this.value5 = this.form.goodrating / 20
            this.playercount = response.data.player_count
          })
    },
    getplayer(gameid){
      axios.get(this.$globle.allurl + "getplayer/" + gameid).then(res=>{

        this.playercount = res.data.response.player_count
      })
    }
  },
  mounted() {
    this.load1();
  },
  components: {
    Header,
    // PeopleNumber,
    // HotSpecialty,
    // Swiperlist,
  },
};
</script>

<style scoped>
.title_1 {
  text-align: center;
  font-size: 50px;
  border-bottom: 1px solid green;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.price {
  font-size: 75px;
  color: #fa4d00;
  font-weight: bold;
}

.text1 {
  font-size: 64px;
  color: #fa4d00;
  font-weight: bold;
}

.container {
  float: left;
  width: 960px;
  margin: 0 auto;
  /*margin-top: 0.24619rem !* 70/147 *!;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.data {
  text-align: center;
  margin: 0 auto;
}
</style>
