<template>
  <div class="login">
    <!-- 动态背景 -->

    <div id="area" class="area">
      <!-- 登录 -->
      <div class="container" v-if="login">
        <div class="title">登录</div>
        <form>
          <div
              class="content"
              v-for="(item, index) of loginValues"
              :key="index"
          >
            <span>{{ item.title }}</span
            ><input :type="item.type" v-model="item.value" />
          </div>
        </form>
        <button class="submit" @click="changeLogin(loginValues)">登录</button>
        <div>
          <el-link type="primary" @click="register" class="link">去注册 ></el-link>
        </div>
      </div>
      <!-- 注册 -->
      <div style="margin-top: 100px">
        <h3>测试账号</h3>
        <p>邮箱：1</p>
        <p>密码：1</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      login: true,
      user:{},
      loginValues: [
        {
          title: "邮箱",
          type: "text",
          value: "1",
        },
        {
          title: "密码",
          type: "password",
          value: "1",
        },
      ],
    };
  },
  methods: {
      register(){
          this.$router.push('/register');
      },
    //   登录按钮
    changeLogin(values) {
      for (let i in values) {

        if (!values[i].value) {
          alert(`${values[i].title}不能为空！`);
        } else {
          this.user.username=1
          this.user.password=1
          axios
              .post(this.$globle.allurl + "user/login",this.user)
              .then((response) => {
                if(response.data !== ""){
                  localStorage.setItem("user", JSON.stringify(response.data))
                  axios.interceptors.request

                  this.$router.push("/home");
                  this.$message({
                    type:"success",
                    message:"登录成功"
                  });
                }
                else {
                  this.$message({
                    type:"fail",
                    message:"登录失败"
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                console.log("账号密码错误！");
              });
        }
      }
    },
  },
  mounted() {

    this.changeLogin(this.loginValues)
  },
};
</script>

<style scoped>
.area {
  padding: 150px /* 150/147 */ 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.container {
  width: 560px /* 500/147 */;
  margin: 0 auto;
  font-size: 20px /* 14/147 */;
  background-color: rgba(248, 248, 248, 0.1);
  border-radius: 10px /* 10/147 */;
  box-shadow: rgb(143, 143, 143) 0 0 20px /* 20/147 */;
  padding: 0 0 10px /* 10/147 */;
}

.content {
  margin: 10px /* 10/147 */;
}

.content > span {
  display: inline-block;
  width: 80px /* 80/147 */;
}

.content > input {
  width: 230px /* 200/147 */;
  height: 30px /* 20/147 */;
  font-size: 20px /* 14/147 */;
}

.title {
  padding: 15px /* 10/147 */ 0;
  color: white;
  font-size: 30px /* 20/147 */;
  font-weight: bold;
  width: 100%;
  background-color: cornflowerblue;
  border-radius: 15px /* 10/147 */ 10px /* 10/147 */ 0 0;
}

.submit {
  display: inline-block;
  background-color: #abcdef;
  border-radius: 5px /* 5/147 */;
  width: 80px /* 80/147 */;
  height: 40px /* 40/147 */;
  color: coral;
  border: none;
  cursor: pointer;
  font-size: 20px /* 16/147 */;
}

.link {
  text-align: center;
  font-size: 12px /* 12/147 */;
}
</style>
