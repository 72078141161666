<template>
  <div>
    <el-row :gutter="10" type="flex">
      <el-col class="data" :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <Header/>

        <div class="container">
          <!--        主体部分-->
          <el-main class="main-f">
            <el-card shadow="never">
              <div class="title_1">{{ passage.title }}</div>
              <div>
                <el-row style="padding: 20px;text-align: left" v-html="passage.text">
                </el-row>
              </div>
            </el-card>
          </el-main>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/Header/index";
import axios from "axios";

export default {
  data() {
    return {
      passage:''
    };
  },
  methods: {
    getoneartical() {
      var id = this.$route.query.id;
      axios.get(this.$globle.allurl + "getoneartical", {
        params: {
          id: id
        }
      }).then(res => {
        this.passage=res.data;
      })
    }
  },

  components: {
    Header,
  },
  mounted() {
    this.getoneartical()
  },
};
</script>

<style scoped>
.container {
  margin: 0 auto;
  margin-top: 0.24619rem /* 70/147 */;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.data {
  text-align: center;
  margin: 0 auto;
}
</style>
