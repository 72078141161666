<template>
    <div>
        <div id="yearmain" style="height: 500px;width: 1200px">

        </div>
        <div id="yearmain1" style="height: 500px;width: 1200px">

        </div>
        <div id="yearmain2" style="height: 500px;width: 1200px">

        </div>
        <div id="main3" style="height: 500px;width: 1200px">

        </div>
    </div>

</template>

<script>
import echarts from "echarts";
import axios from "axios";

export default {
    name: "year-count",
    data(){
        return{
            xdata:[],
            ydata:[]
        }
    },
    methods: {
        drawChart() {
            this.myChart = echarts.init(document.getElementById('yearmain'));
            this.myChart1 = echarts.init(document.getElementById('yearmain1'));
            this.myChart2 = echarts.init(document.getElementById('yearmain2'));
            this.myChart3 = echarts.init(document.getElementById('main3'));
            axios.get(this.$globle.allurl + "yearcount").then(res => {

                this.myChart.setOption({
                    title:{
                        text:'游戏发售年份柱状图',
                        left:'center'
                    },
                    dataset: [
                        {
                            dimensions: ['year', 'count'],
                            source: res.data.sort(function (a, b) {
                                return b.count - a.count;
                            }),
                        },
                    ],
                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {},
                    series: {type: 'bar'},
                    dataZoom: {
                        show: true, // 为true 滚动条出现
                        realtime: true,
                        type: 'slider', // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
                        height: 20, // 表示滚动条的高度，也就是粗细
                        start: 0, // 表示默认展示20%～80%这一段。
                        end: 2
                    }
                });
                this.myChart1.setOption({
                    title:{
                        text:'游戏发售年份饼图',
                        left:'center'
                    },
                    dataset: [
                        {
                            dimensions: ['count', 'year'],
                            source: res.data.sort(function (a, b) {
                                return b.count - a.count;
                            }),
                        },
                    ],
                    series: {type: 'pie'},
                });
                this.myChart2.setOption({
                    title:{
                        text:'游戏发售年份折线图',
                        left:'center'
                    },
                    dataset: [
                        {
                            dimensions: ['year', 'count'],
                            source: res.data.sort(function (a, b) {
                                return a.count - b.count;
                            }),
                        },
                    ],
                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {},
                    series: {
                        type: 'line',
                        encode: {
                            x: 'year',
                            y: 'count',
                            // tooltip: ['入炉锰', '总价格'] // 更改 在 tooltip中显示的属性，即鼠标悬浮时的显示
                        },
                    },
                });
            })
        },
    },
    mounted() {
        this.drawChart()
    }
}
</script>

<style scoped>

</style>
