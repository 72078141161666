import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Rem from '../Rme.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import globle from "@/globle";
import axios from './axios/axios-interceptors'
Vue.use(ElementUI)
//不能用vue.use引入，会导致请求拦截错误，无限跳转首页
Vue.prototype.$axios=axios
Vue.prototype.$globle = globle
Vue.config.productionTip = false
// Vue.prototype.steam = '/steam';
// Vue.prototype.psname = '/psname';
new Vue({
  Rem,
  router,
  render: h => h(App)
}).$mount('#app')
