<template>
    <div>
        <Header/>
        <div class="container">
            <!-- 左侧内容显示 -->
            <div class="container-left">
                <table style="width: 100%; border-collapse: collapse">
                    <template
                            v-for="(i,index) in news"
                    >
                        <tr :key="index"
                            style="
                                border-bottom: 1px solid #eeeeee;
                                border-top: 1px solid #eeeeee;
                              ">
                            <el-card shadow="hover">
                                <a @click="changeLeftItem(i.id)" class="colorhover" style="font-size: 25px">{{
                                    i.title
                                    }}</a>
                            </el-card>

                        </tr>
                    </template>
                </table>

                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>

                <!-- 右侧内容显示 -->


                <!-- 弹窗 -->
            </div>
            <Swiperlist/>

        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header/index";
import Footer from "@/components/Footer";
import Swiperlist from "@/components/Swiperlist/index.vue";
import axios from "axios";

export default {
    components: {
        Header,
        Footer,
        Swiperlist
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 20,
            total: 0,
            news: []
        };
    },
    methods: {

        articalget() {
            axios.get(this.$globle.allurl + "pageartical", {
                params: {
                    pagenum: this.currentPage,
                    pagesize: this.pageSize,
                }
            }).then(res => {

                this.news = res.data.records
                this.total = res.data.total
            })
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.articalget()
        },

        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.articalget()
        },
        changeLeftItem(index) {
            this.$router.push({
                name: "information",
                query: {
                    id: index,
                },
            });
        },
    },
    created() {
        this.articalget()
    }
};
</script>

<style scoped>
.container {
    width: 8.163265rem /* 1200/147 */;
    margin: 0 auto;
    /*margin-top: 0.47619rem !* 70/147 *!;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-left {
    width: 900px;
    background-color: white;
    margin-bottom: 20px;
    font-size: 14px;
}

.colorhover:hover {
    color: orange;
    cursor: pointer;
}
</style>
