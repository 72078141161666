<template>
    <div class="head">
        <el-row :gutter="10" :span="24">
            <div class="titles" style="height: 100px;">
                <el-col :span="7"><span>
<!--          网站图标-->
            <img style="width: 180px;height: 70px;"
                 src="../../../public/shuyouwang.jpg">
          </span></el-col>
                <el-col :span="10">
                    <div class="search">
                        <img src="https://pbmp.ali213.net/Pics/2023/index_tl1528_1200c100.jpg" style="width: 100%;">
                    </div>
                </el-col>
                <el-col :span="7">
                    <el-row>
                        <el-col :span="12">
                            <span class="el-dropdown-link" style="float: right">{{ user.nickname }}</span>
                        </el-col>
                    </el-row>
                </el-col>
            </div>
        </el-row>
        <div class="navs">
            <a
                    v-for="item of navList"
                    :key="item.id"
                    class="navs-router"
                    :style="{ color: item.color, borderBottom: item.borderBottom }"

                    :href="item.url"
            >
                {{ item.title }}
            </a>
        </div>
        <el-divider></el-divider>
    </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";

export default {
    data() {
        return {
            user: {
                username: "万帅"
            },
            navList: [
                {
                    id: "1",
                    title: "首页",
                    url: "/home",
                    borderBottom: "1px solid white",
                    color: "black",
                },
                {
                    id: "2",
                    title: "游戏推荐",
                    url: "/details",
                    borderBottom: "1px solid white",
                    color: "black",
                },
                {
                    id: "3",
                    title: "查游戏",
                    url: "/game",
                    borderBottom: "1px solid white",
                    color: "black",
                },
                {
                    id: "4",
                    title: "看新闻",
                    url: "/informations",
                    borderBottom: "1px solid white",
                    color: "black",
                },
                {
                    id: "5",
                    title: "图表数据",
                    url: "/charts",
                    borderBottom: "1px solid white",
                    color: "black",
                },
                // {
                //   id: "4",
                //   title: "查专业",
                //   url: "/professional",
                //   borderBottom: "1px solid white",
                //   color: "black",
                // },
                {
                    id: "6",
                    title: "个人中心",
                    url: "/userdetail",
                    borderBottom: "1px solid white",
                    color: "black",
                },
            ],
        };
    },
    props: {
        index: {
            type: String,
            default: "0",
        },
    },
    methods: {
        detail() {
            this.$router.push({
                name: "userdetail"
            })
        },
    },
    mounted() {
    },
    created() {
        let user = localStorage.getItem("user") || "{}"
        this.user = JSON.parse(user)
    },
};
</script>

<style scoped>
.head {
    width: 100% /* 1200/147 */;
    margin: 0 auto;
    /*font-size: 0.108844rem !* 16/147 *!;*/
}

.titles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.input1 {
    width: 100% /* 500/147 */;
    height: 51px /* 38/147 */;
    border: 1px solid rgb(221, 221, 221);
    font-size: 20px /* 14/147 */;
}

.btn1 {
    display: inline-block;
    width: 50% /* 100/147 */;
    height: 51.78px /* 40/147 */;
    font-size: 20px /* 14/147 */;
    cursor: pointer;
    background-color: tomato;
    border: none;
    color: white;
}

.navs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    flex-wrap: wrap;
}

.navs-router {
    font-size: 18px;
    text-decoration: none;
}
</style>
