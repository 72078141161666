import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home/index.vue'
import Login from '../pages/Login/index.vue'
import game from '../pages/game/index.vue'
import gameDetail from '../pages/game/gameDetail.vue'
import Details from '../pages/Details/index.vue'
import Information from '../pages/Information/infomation.vue'
import Informations from '../pages/Informations/index.vue'
import Consultant from '../pages/Consultant/index.vue'
import Fufei from '../pages/Fufei/index.vue'
import userdetail from "@/pages/userdetail/userdetail";
import Charts from '../pages/Charts/index.vue'
import ConsultantDetail from '../pages/Consultant/ConsultantDetail.vue'
import axios from "axios";
import globle from "@/globle";
import register from "@/pages/register/register.vue";
import psgamedetial from "../pages/game/psgamedetial.vue";

// const originalPush = VueRouter.prototype.push
//
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const routes = [
  {
    path: '/ConsultantDetail',
    name: 'ConsultantDetail',
    component: ConsultantDetail,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/fufei',
    name: 'fufei',
    component: Fufei,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/gameDetail',
    name: 'gameDetail',
    component: gameDetail,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/psgameDetail',
    name: 'psgameDetail',
    component: psgamedetial,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/consultant',
    name: 'consultant',
    component: Consultant,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  //   meta: {
  //     keepAlive: false // 不需要缓存
  //   }
  // },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/register',
    name:'register',
    component: register,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/information',
    name: 'information',
    component: Information,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/informations',
    name: 'informations',
    component: Informations,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/',
    name: 'game',
    component: game,
    meta: {
      keepAlive: true, //该字段表示该页面需要缓存
    },
  },
  {
    path: '/game',
    name: 'game',
    component: game,
    meta: {
      keepAlive: true, //该字段表示该页面需要缓存
    },
  },
  {
    path: '/details',
    name: 'details',
    component: Details,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/userdetail',
    name: 'userdetail',
    component: userdetail,
    meta: {
      keepAlive: false // 不需要缓存
    }
  },
  {
    path: '/charts',
    name: 'charts',
    component: Charts,
    meta: {
      keepAlive: false // 不需要缓存
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   let user="";
//   user=JSON.parse(sessionStorage.getItem("user"));
//   if (to.name !== 'login' &&user===null) next({ name: 'login' })
//   else next()
// })
router.beforeEach((to, from, next) => {
  let user = "";
  user = JSON.parse(localStorage.getItem("user"));
  if (to.name === 'login'||to.name === 'register') next()
  else {
    if (user === null) next({name: 'login'})
    else {
      axios.get(globle.allurl + "checktoken").then(res => {
        if (res.data) {
          next()
        } else {
          alert("请重新登录")
          next({name: 'login'})
        }
      })
    }
  }
})
export default router
