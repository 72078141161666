<template>
  <div>
    <Header index="2"/>
    <div class="title_1">{{ this.form.name }}</div>
    <div>
      <el-carousel :interval="4000" type="card" height="420px">
        <el-carousel-item v-for="item in this.form.picdetial" :key="item">
          <img :src="item" style="width: 757.5px;height: 432px"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-row :gutter="10" type="flex">
      <el-col class="data" :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
        <div class="container">
          <el-container>
            <el-main class="main-f">
              <el-card shadow="never">
                <el-row>
                  <el-col :span="8">
                    <div
                        class="grid-content bg-purple-light"
                        style="width: 100%"
                    >
                      <img
                          style="width: 256px;height: 256px"
                          v-bind:src="form.picmulu"
                      />
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="grid-content bg-purple">
                      <el-row>
                        <div
                            style="font-size: 30px;color: red"
                        >
                          {{ form.name }}
                        </div>
                      </el-row>
                      <el-row style="margin:20px auto">
                        <el-col :span="12">
                            <el-rate
                                v-model="form.averageRating"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template="{value}"
                            >
                            </el-rate>
                        </el-col>
                        <el-col :span="12">
                          总评价人数：{{form.totalRatingsCount}}
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="24">
                          <div class="grid-content bg-purple-light">
                            <el-tag
                                type="success"
                                style="font-size: 20px"
                            >
                              游戏id：{{ form.gameid }}
                            </el-tag>
                            <el-tag
                                type="success"
                                style="font-size: 20px"
                            >
                              游戏类型：{{ form.type }}
                            </el-tag>
                            <el-tag
                                type="success"
                                style="font-size: 20px"
                            >
                              发行日期：{{ form.releaseDate }}
                            </el-tag>
                            <el-tag
                                type="success"
                                style="font-size: 20px"
                            >
                              发行商：{{ form.publisherName }}
                            </el-tag>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>

                </el-row>
              </el-card>
              <el-card shadow="never">
                <div v-html="form.descriptions"></div>
              </el-card>

            </el-main>
          </el-container>

        </div>
        <div>
          <el-card body-style="display:grid">
            <span class="price">{{ form.basePrice }}</span>
            <el-button type="primary" @click="changelink(form.gameid)">前往psn</el-button>
          </el-card>
          <el-card>
            <span class="text1">屏幕语言</span>
            <el-tag v-for="item in language" :key="item" style="font-size: 30px">{{ item }}</el-tag>
          </el-card>
          <el-card>
            <span class="text1">配音语言</span>
            <el-tag v-for="item in splanguage" :key="item" style="font-size: 30px">{{ item }}</el-tag>
          </el-card>
          <el-card>
            <span class="text1">区服</span>
            <el-tag style="font-size: 30px">{{ form.region}}</el-tag>
          </el-card>
        </div>
        <!--        <swiperlist style="float: right"/>-->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Header from "@/components/Header/index";
// import PeopleNumber from "@/pages/Charts/PeopleNumber";
// import HotSpecialty from "@/pages/Charts/HotSpecialty";
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      fit: "",
      formInline: {
        user: "",
        region: "",
      },
      url: "static/img/htt/1.jpeg",
      form: {},
      yearMajor: {},
      input: "",
      comments: [],
      tab: [],
      language: [],
      splanguage:[]
    };
  },
  methods: {
    changelink(event) {
      // window.location.href = event;
      // 新窗口打开
      window.open("https://store.playstation.com/"+this.form.region+"/concept/"+event);
    },
    shop(id) {
      axios.get(this.$globle.allurl + "shopgame", {
        params: {
          userid: JSON.parse(localStorage.getItem("user") || "{}").id,
          gameid: id
        }
      }).then(res => {
        if (res.data == 1) {
          this.$alert('游戏已购买', '购买失败', {
            confirmButtonText: '确定',
          });
        } else if (res.data == 2) {
          this.$alert('账户余额不足', '购买失败', {
            confirmButtonText: '确定',
          });
        } else if (res.data == 3) {
          this.$alert('游戏愉快', '购买成功', {
            confirmButtonText: '确定',
          });
        }
      })
    },
    load1() {
      var param = this.$route.query.id;
      axios
          .get(this.$globle.allurl + "ps/getonepsgame/" + param)
          .then((response) => {
            this.form = response.data;
            this.language = this.form.screenLanguages.split("\n")
            this.splanguage = this.form.spokenLanguages.split("\n")
            this.form.picdetial = this.form.picdetial.split("\n")
          })
    },
    // 获取该院校专业
  },
  mounted() {
    this.load1();
  },
  components: {
    Header,
    // PeopleNumber,
    // HotSpecialty,
    // Swiperlist,
  },
};
</script>

<style scoped>
.title_1 {
  text-align: center;
  font-size: 50px;
  border-bottom: 1px solid green;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.price {
  font-size: 50px;
  color: #fa4d00;
  font-weight: bold;
}

.text1 {
  font-size: 64px;
  color: #fa4d00;
  font-weight: bold;
}

.container {
  float: left;
  width: 960px;
  margin: 0 auto;
  /*margin-top: 0.24619rem !* 70/147 *!;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.data {
  text-align: center;
  margin: 0 auto;
}
</style>
